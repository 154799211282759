import { render, staticRenderFns } from "./FundingManager.vue?vue&type=template&id=dc2d923a"
import script from "./FundingManager.vue?vue&type=script&lang=js"
export * from "./FundingManager.vue?vue&type=script&lang=js"
import style0 from "./FundingManager.vue?vue&type=style&index=0&id=dc2d923a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports